<template>
  <q-card>
    <q-card-section class="text-h6">
      Other Creditors
    </q-card-section>
    <q-card-section>
      <div :key="index" v-for="(credit, index) in other_creditors">
          <q-separator v-if="index !== 0" />

          <div class="q-pa-md col-lg-12 text-left">
            <q-btn
              color="red-5"
              label="Delete Other Creditors"
              icon="delete"
              size="sm"
              v-if="index !== 0"
              @click="remove(index)"
            />
          </div>

          <div class="row">

            <div class="col-md-4 col-xs-12 q-pa-md">
              <q-field>
                <q-input v-model="credit.type"
                         label="Type"
                />
              </q-field>
            </div>

            <div class="col-md-4 col-xs-12 q-pa-md">
              <q-field>
                <q-input type="number"
                         v-model="credit.out_balance"
                         label="Outstanding Balance"
                />
              </q-field>
            </div>

            <div class="col-md-4 col-xs-12 q-pa-md">
              <q-field>
                <q-input type="number"
                         v-model="credit.monthly_amount"
                         label="Monthly Instalment Amount"
                />
              </q-field>
            </div>
          </div>
        </div>

        <div class="col-lg-12 q-pa-md text-left">
          <q-btn color="secondary" align="center" size="sm" @click="add" label="Add Other Creditors"></q-btn>
        </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'other-creditors',
  data () {
    return {
      other_creditors: []
    }
  },
  mounted () {
    this.add()
  },
  watch: {
    other_creditors () {
      this.$emit('input', this.other_creditors)
    }
  },
  methods: {
    add () {
      this.other_creditors.push({
        type: '',
        out_balance: '',
        monthly_amount: ''
      })
    },
    remove (index) {
      this.other_creditors.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
