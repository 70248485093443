<template>
    <div>
      <q-page class="q-pl-md q-pr-md">
        <IncomeAndExpForm :amount="amount" @sent="onSent"></IncomeAndExpForm>
      </q-page>
    </div>
</template>

<script>
var CryptoJS = require('crypto-js')

import IncomeAndExpForm from '../components/IncomeAndExpForm'
export default {
  name: 'income-and-exp-page',
  components: {
    IncomeAndExpForm
  },
  data () {
    return {
      amount: ''
    }
  },
  methods: {
    onSent () {
      alert('xxx')
    }
  },
  mounted () {
    var bytes = CryptoJS.AES.decrypt(this.$route.params.token, 'schlegermeier')
    var amount = bytes.toString(CryptoJS.enc.Utf8)

    // if (!amount) {
    //   this.$router.push({ name: '404' })
    // }

    this.amount = amount
  }
}
</script>

<style scoped>

</style>
