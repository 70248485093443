<template>
  <q-form @submit="onSubmit">
    <q-card>
      <q-card-section class="text-h6">
        Incomes
      </q-card-section>
      <q-card-section class="row wrap">
        <div class="col-xs-12 col-sm-6 col-md-4 q-pa-md" :key="i" v-for="(field, i) in incomes">
          <q-field borderless>
            <component
              :is="field.component"
              :type="field.type"
              v-model="form[field.name]"
              v-bind="field.props || {}"
              :label="field.label"
              :rules="field.roles ? field.roles : [val => !!val || 'Field is required']">
              <template v-slot:before>
                <q-icon :name="field.icon"/>
              </template>
            </component>
          </q-field>
        </div>
      </q-card-section>
    </q-card>

    <br/>

    <q-card>
      <q-card-section class="text-h6">
        Expenditures
      </q-card-section>
      <q-card-section class="row wrap">
        <div class="col-xs-12 col-sm-4 col-md-4 q-pa-md" :key="i" v-for="(field, i) in expenditures">
          <q-field borderless>
            <component
              :is="field.component"
              :type="field.type"
              v-model="form[field.name]"
              v-bind="field.props || {}"
              :label="field.label"
              :rules="field.roles ? field.roles : [val => !!val || 'Field is required']">
              <template v-slot:before>
                <q-icon :name="field.icon"/>
              </template>
            </component>
          </q-field>
        </div>
      </q-card-section>
    </q-card>

    <br/>

    <OtherCreditors v-model="form.other_creditors"/>

    <br/>

    <q-card>
      <q-card-section class="text-h6">
        Enter the best time to try and contact you.
      </q-card-section>
      <q-card-section class="row wrap">
        <div class="col-xs-12 col-sm-6 col-md-4 q-pa-md" :key="i" v-for="(field, i) in callbackDetails">
          <q-field borderless>
            <component
              :is="field.component"
              :type="field.type"
              v-model="form[field.name]"
              v-bind="field.props || {}"
              :label="field.label"
              :rules="field.roles ? field.roles : [val => !!val || 'Field is required']">
              <template v-slot:before>
                <q-icon :name="field.icon"/>
              </template>
            </component>
          </q-field>
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn style="min-width: 100px" class="bgBtnLiner text-white" type="submit" rounded label="Submit"/>
      </q-card-actions>
    </q-card>
  </q-form>
</template>

<script>
import OtherCreditors from './OtherCreditors'
import moment from 'moment'
export default {
  name: 'income-and-exp-form',
  props: {
    amount: {}
  },
  components: {
    OtherCreditors
  },
  data () {
    return {
      incomes: [
        {
          name: 'number_of_adults',
          component: 'QSelect',
          props: {
            'options': window._.range(1, 10)
          },
          label: 'Number Of Adults',
          icon: 'group'
        },
        {
          name: 'number_of_dependent_children',
          component: 'QSelect',
          props: {
            'options': window._.range(0, 10)
          },
          label: 'Number Of Dependent Children',
          icon: 'groups',
          roles: [val => (!!val || val === 0) || 'Field is required']
        },
        {
          name: 'reasons_financial_difficulty',
          component: 'QInput',
          label: 'Reasons For Financial Difficulty',
          icon: 'report_problem'
        },
        {
          name: 'wages',
          component: 'QInput',
          type: 'number',
          label: 'Wages',
          icon: 'pound_symbol'
        },
        {
          name: 'other_income',
          component: 'QInput',
          type: 'number',
          label: 'Other Income (including Benefits)',
          icon: 'pound_symbol'
        },
        {
          name: 'work_pay_day',
          component: 'QSelect',
          props: {
            'options': window._.range(1, 32)
          },
          label: 'Work pay date (day of wages)',
          icon: 'today'
        }
      ],
      expenditures: [
        {
          name: 'rent',
          component: 'QInput',
          type: 'number',
          label: 'Rent',
          icon: 'home'
        },
        {
          name: 'rent_arrears',
          component: 'QInput',
          type: 'number',
          label: 'Rent Arrears',
          icon: 'home',
          roles: []
        },
        {
          name: 'monthly_rent_arrears_payment',
          component: 'QInput',
          type: 'number',
          label: 'Monthly Rent Arrears Payment',
          icon: 'home',
          roles: []
        },
        {
          name: 'council_Tax',
          component: 'QInput',
          type: 'number',
          label: 'Council Tax',
          icon: 'account_balance'
        },
        {
          name: 'council_tax_arrears',
          component: 'QInput',
          type: 'number',
          label: 'Council Tax Arrears',
          icon: 'account_box',
          roles: []
        },
        {
          name: 'monthly_council_tax_arrears_payment',
          component: 'QInput',
          type: 'number',
          label: 'Monthly Council Tax Arrears Payment',
          icon: 'account_box',
          roles: []
        },
        {
          name: 'water_exp',
          component: 'QInput',
          type: 'number',
          label: 'Water',
          icon: 'waves'
        },
        {
          name: 'gas_and_electric_exp',
          component: 'QInput',
          type: 'number',
          label: 'Gas & Electric',
          icon: 'flash_on'
        },
        {
          name: 'internet_exp',
          component: 'QInput',
          type: 'number',
          label: 'Internet',
          icon: 'wifi'
        },
        {
          name: 'tv_exp',
          component: 'QInput',
          type: 'number',
          label: 'TV',
          icon: 'live_tv',
          roles: []
        },
        {
          name: 'insurances',
          component: 'QInput',
          type: 'number',
          label: 'Insurances',
          icon: 'account_box',
          roles: []
        },
        {
          name: 'home_phone',
          component: 'QInput',
          type: 'number',
          label: 'Home Phone',
          icon: 'phone',
          roles: []
        },
        {
          name: 'mobile_phone',
          component: 'QInput',
          type: 'number',
          label: 'Mobile Phone',
          icon: 'smartphone',
          roles: []
        },
        {
          name: 'housekeeping',
          component: 'QInput',
          type: 'number',
          label: 'Housekeeping',
          icon: 'account_box',
          roles: []
        },
        {
          name: 'children_pocket_money',
          component: 'QInput',
          type: 'number',
          label: 'Children Pocket Money',
          icon: 'account_box',
          roles: []
        },
        {
          name: 'work_dinners',
          component: 'QInput',
          type: 'number',
          label: 'Work Dinners',
          icon: 'child_friendly',
          roles: []
        },
        {
          name: 'gym_membership',
          component: 'QInput',
          type: 'number',
          label: 'Gym Membership',
          icon: 'account_box',
          roles: []
        },
        {
          name: 'union_fees',
          component: 'QInput',
          type: 'number',
          label: 'Union Fees',
          icon: 'account_box',
          roles: []
        },
        {
          name: 'clothing',
          component: 'QInput',
          type: 'number',
          label: 'Clothing',
          icon: 'child_friendly',
          roles: []
        },
        {
          name: 'hobbies',
          component: 'QInput',
          type: 'number',
          label: 'Hobbies',
          icon: 'sports_tennis',
          roles: []
        },
        {
          name: 'private_medical_cover',
          component: 'QInput',
          type: 'number',
          label: 'Private Medical Cover',
          icon: 'healing',
          roles: []
        },
        {
          name: 'child_maintenance',
          component: 'QInput',
          type: 'number',
          label: 'Child Maintenance',
          icon: 'child_friendly',
          roles: []
        }
      ],
      form: {
        number_of_adults: '',
        number_of_dependent_children: '',
        reasons_financial_difficulty: '',
        wages: '',
        work_pay_day: '',
        other_income: '',
        rent: '',
        rent_arrears: '',
        monthly_rent_arrears_payment: '',
        council_tax: '',
        council_tax_arrears: '',
        monthly_council_tax_arrears_payment: '',
        insurances: '',
        water_exp: '',
        gas_and_electric_exp: '',
        tv_exp: '',
        internet_exp: '',
        home_phone: '',
        mobile_phone: '',
        housekeeping: '',
        children_pocket_money: '',
        work_dinners: '',
        gym_membership: '',
        union_fees: '',
        clothing: '',
        hobbies: '',
        private_medical_cover: '',
        child_maintenance: '',
        other_creditors: [
          {
            type: '',
            out_balance: '',
            monthly_amount: ''
          }
        ],
        date: '',
        time: ''
      },
      timeOptions: [
        {
          startTime: 8,
          title: '8:00 - 10:00'
        },
        {
          startTime: 10,
          title: '10:00 - 12:00'
        },
        {
          startTime: 12,
          title: '12:00 - 2:00'
        },
        {
          startTime: 14,
          title: '2:00 - 5:00'
        }
      ]
    }
  },
  methods: {
    async onSubmit () {
      let sent = await this.$dispatch({
        action: 'customer/incomeAndExp',
        message: 'Your Income and Expenditure Report has been submitted',
        successMessage: 'Your Income and Expenditure Report sent successfully.',
        params: this.form
      })

      if (sent) {
        await this.$dispatch([
          {
            action: 'customer/getCustomer',
            message: 'Loading Customer Data...',
            params: true
          }])
        this.$router.replace({ name: 'dashboard' })
      }
    }
  },
  computed: {
    dateOptions () {
      let dates = []
      let current = moment()

      if (current.hour() > 14) {
        current = current.add(1, 'days')
      }

      for (let i = 0; i < 14; i++) {
        if (current.day() > 0 && current.day() < 6) {
          dates.push(current.format('DD  MMMM'))
        }
        current = current.add(1, 'days')
      }
      return dates
    },
    currentTimeOptions () {
      if (!moment().isSame(moment(this.form.date, 'DD MMMM'), 'days')) {
        return this.timeOptions.map(t => t.title)
      }
      return this.timeOptions
        .filter(t => moment().hour() < t.startTime)
        .map(t => t.title)
    },
    callbackDetails () {
      return [
        {
          name: 'date',
          component: 'QSelect',
          props: {
            'options': this.dateOptions
          },
          label: 'Choose Date',
          icon: 'event'
        },
        {
          name: 'time',
          component: 'QSelect',
          props: {
            'options': this.currentTimeOptions
          },
          label: 'Choose Time',
          icon: 'schedule'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
